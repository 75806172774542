import { useContext, useDebugValue } from 'react';
import { SessionContext } from '@/context/SessionProvider';

const useSession = () => {
  const context = useContext(SessionContext);
  const { session } = context;
  useDebugValue(session, (session) =>
    session?.email ? 'Logged In' : 'Logged Out'
  );
  return context;
};

export default useSession;
