import styles from './filter-summary.module.scss';
import PlusIcon from '@/assets/icons/plus.svg?react';
import GearIcon from '@/assets/icons/gear.svg?react';
import { classnames } from '@/utils/functions';
import useResponsive from '@/hooks/useResponsive';
import { FC } from 'react';

interface FilterSummaryProps {
  label: string;
  value: string;
  fallbackValue?: string;
  handleClick: () => void;
}

const FilterSummary: FC<FilterSummaryProps> = ({
  label,
  value,
  fallbackValue = 'All',
  handleClick,
}) => {
  const itemsStr = value || fallbackValue;
  const { isMobile } = useResponsive();
  return (
    <div
      className={classnames(styles['filter-summary'], {
        [styles['has-values']]: itemsStr !== fallbackValue,
      })}
    >
      <span>{label}:</span>
      <button
        title={itemsStr}
        onClick={() => {
          handleClick();
        }}
      >
        <p>{isMobile ? <>{label}</> : itemsStr}</p>
        {itemsStr === fallbackValue ? <PlusIcon /> : <GearIcon />}
      </button>
    </div>
  );
};

export default FilterSummary;
