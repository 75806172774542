import { AWS_LLM_API } from '@/config/aws';
import { APIThread, Thread } from '@/models/thread';
import { extractPayload, getSubHeader } from '@/utils/api';
import { convertTimestampToString } from '@/utils/functions';
import { get } from 'aws-amplify/api';

interface ThreadsPayload {
  data: {
    threadData: APIThread[];
  };
}

const fetchThreads = async (userSub: string): Promise<Thread[]> => {
  const threads: Thread[] = [];

  const { response } = get({
    apiName: AWS_LLM_API,
    path: '/v1/base/conversation/threads',
    options: {
      headers: getSubHeader(userSub),
      queryParams: {
        page: '1',
        perPage: '100',
      },
    },
  });
  const payload = await extractPayload<ThreadsPayload>(response);

  for (const thread of payload.data.threadData) {
    threads.push({
      id: thread.thread_id,
      title: thread.title,
      blocks: thread.thread_items.map((block) => ({
        id: block.message_id,
        disliked: false,
        prompt: block.user_input,
        queryDate: new Date(block.query_ts),
        response: block.llm_response,
        sources: block.rag_sources,
        cost: block.cost,
      })),
      filters: {
        countries: thread.filters.countries,
        sources: thread.filters.source_category,
        earliestDate: convertTimestampToString(
          thread.filters.publish_date.earliest
        ),
        latestDate: convertTimestampToString(
          thread.filters.publish_date.latest
        ),
      },
    });
  }

  return threads;
};

export default fetchThreads;
