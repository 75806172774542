import useThread from '../../hooks/useThread';
import styles from './suggestions.module.scss';
import suggestions from '@/data/suggestions.json';
import useSession from '@/hooks/useSession';
const Suggestions = () => {
  const { pushUserEvent } = useSession();
  const { handlePromptSubmit } = useThread();

  const handleSuggestionClick = (suggestion: string) => {
    pushUserEvent('click_suggestion');
    handlePromptSubmit(suggestion);
  };

  return (
    <ul className={styles['suggestions']}>
      {suggestions.map((suggestion: string, i: number) => (
        <li
          data-testid="suggestion"
          key={i}
          className={styles['suggestion']}
          onClick={() => handleSuggestionClick(suggestion)}
        >
          {suggestion}
        </li>
      ))}
    </ul>
  );
};

export default Suggestions;
