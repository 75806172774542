import { FC, FormEvent, FunctionComponent, useState } from 'react';
import styles from './message-options.module.scss';
import { ThreadMessageAuthor } from '@/models/thread';
import useBlock from '../../hooks/useBlock';
import useThread from '../../hooks/useThread';
import CopyIcon from '@/assets/icons/copy.svg?react';
import DislikeIcon from '@/assets/icons/dislike.svg?react';
import Modal from '@/components/Modal/modal';
import { createPortal } from 'react-dom';
import Form from '@/components/Form';
import InputWrapper from '@/components/Form/InputWrapper';
import Button from '@/components/Button';
import MultiOptionInput from '@/components/MultiOptionInput';
import addMessageFeedback from '@/api/threads/addMessageFeedback';
import useSession from '@/hooks/useSession';
import EmailLink from '@/components/EmailLink';

interface MessageOptionsProps {
  author: ThreadMessageAuthor;
  content: string;
}

interface Option {
  title: string;
  id: string;
  Icon: FunctionComponent;
  action: () => void;
}

const FEEDBACK_REASONS = [
  {
    id: 'did_not_follow_instructions',
    label: "Didn't fully follow instructions",
  },
  { id: 'dislike_style', label: "Don't like the style" },
  { id: 'factually_incorrect', label: 'Not factually correct' },
  { id: 'out_of_date', label: 'Out of date' },
  { id: 'harmful_or_offensive', label: 'Harmful or offensive' },
  { id: 'other', label: 'Other' },
];
const MessageOptions: FC<MessageOptionsProps> = ({ author, content }) => {
  const { status } = useThread();
  const { session, pushUserEvent } = useSession();
  const { block, isLast } = useBlock();
  const [submitStatus, setSubmitStatus] = useState<
    'IDLE' | 'LOADING' | 'DONE' | 'ERROR'
  >('IDLE');
  const [openFeedback, setOpenFeedback] = useState(false);
  const [reasons, setReasons] = useState<string[]>([]);
  const [feedbackDetails, setFeedbackDetails] = useState('');

  if (isLast && status !== 'READY') return null;

  const AssitantOptions: Option[] = [
    {
      title: 'Copy to clipboard',
      id: 'copy-to-clipboard',
      Icon: CopyIcon,
      action: () => {
        let copyContent = content;
        let sources = '\n\n\n----------------\nSources: ';

        if (block.sources) {
          for (const source of block.sources) {
            sources += `\n\n- ${source.title}: ${source.url}`;
          }

          copyContent += sources;
        }

        navigator.clipboard
          .writeText(copyContent)
          .then(() => {
            pushUserEvent('click_copy');
          })
          .catch((err) => {
            console.error('Error in copying text: ', err);
          });
      },
    },
    {
      title: 'Dislike',
      id: 'dislike',
      Icon: DislikeIcon,
      action: () => {
        pushUserEvent('click_dislike');
        setOpenFeedback(true);
      },
    },
  ];

  const options = {
    USER: [],
    ASSISTANT: AssitantOptions.filter(
      ({ id }) => id !== 'regenerate' || isLast
    ),
  };

  const onCheckboxChange = (
    _name: string,
    value: string,
    selected: boolean
  ) => {
    setReasons(selected ? [value] : []);
  };

  const onFeedbackSubmit = (e: FormEvent<HTMLFormElement>) => {
    const formData = new FormData(e.currentTarget);
    setSubmitStatus('LOADING');
    addMessageFeedback(
      session.sub,
      block.id as string,
      FEEDBACK_REASONS.find(({ label }) => label === reasons[0])?.id as string,
      formData.get('details') as string
    )
      .then(() => {
        setSubmitStatus('DONE');
        pushUserEvent('submit_dislike');
      })
      .catch((error) => {
        alert(
          'An error happened while submitting feedback, please try again. if the issue persists, please contact us.'
        );
        console.error(error);
        setSubmitStatus('ERROR');
      });
  };

  const onFeedbackClose = () => {
    setOpenFeedback(false);
    setSubmitStatus('IDLE');
    setReasons([]);
    setFeedbackDetails('');
  };
  return (
    <div className={styles['message-options']}>
      {options[author].map(({ id, Icon, title, action }) => (
        <button key={id} className={styles[id]} onClick={action} title={title}>
          <Icon />
          {(id === 'regenerate' || id === 'blur') && <span>{title}</span>}
        </button>
      ))}

      {createPortal(
        openFeedback && (
          <Modal
            title="Response Feedback"
            isOpen={openFeedback}
            onClose={onFeedbackClose}
          >
            {submitStatus === 'DONE' ? (
              <div className={styles['feedback-success']}>
                <p>
                  Thank you for taking the time to provide feedback on the AI
                  response you received. We sincerely apologize if the
                  information was inaccurate, unhelpful, or harmful in any way.
                </p>
                <p>
                  As <strong>GANNET</strong> is currently in its early beta
                  release, your input is especially crucial. Your feedback helps
                  us identify and address issues, ensuring that we can improve
                  and refine our tool to better serve the needs of the
                  humanitarian sector.
                </p>
                <p>
                  We will carefully review your feedback to make necessary
                  adjustments. Please feel free to reach out to us directly at{' '}
                  <EmailLink /> if you wish to provide additional details or
                  suggestions. Your insights are invaluable to us.
                </p>
                <br />
                <Button variation="secondary" onClick={onFeedbackClose}>
                  Close
                </Button>
              </div>
            ) : (
              <Form onSubmit={onFeedbackSubmit}>
                <div className={styles['feedback-reasons']}>
                  <label htmlFor="feedback">
                    Why do you dislike this message?
                  </label>
                  <span>Select all options that apply</span>
                  <MultiOptionInput
                    options={FEEDBACK_REASONS.map((item) => item.label)}
                    selectedOptions={reasons}
                    name="reasons"
                    variation="options"
                    handleChange={onCheckboxChange}
                  />
                </div>

                <InputWrapper>
                  <textarea
                    name="details"
                    value={feedbackDetails}
                    required={true}
                    rows={8}
                    onChange={(e) => {
                      setFeedbackDetails(e.target.value);
                    }}
                  ></textarea>
                  <label htmlFor="feedback">
                    Please share additional feedback to understand the issue
                  </label>
                </InputWrapper>
                <Button
                  disabled={
                    reasons.length === 0 || feedbackDetails.trim().length === 0
                  }
                  loading={submitStatus === 'LOADING'}
                >
                  Submit
                </Button>
              </Form>
            )}
          </Modal>
        ),
        document.body
      )}
    </div>
  );
};

export default MessageOptions;
