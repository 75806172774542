import styles from './home-layout.module.scss';
import { FC, ReactNode } from 'react';
import { classnames } from '@/utils/functions';
import useSession from '@/hooks/useSession';

interface HomeLayoutProps {
  showNav?: boolean;
  children: ReactNode;
}

const HomeLayout: FC<HomeLayoutProps> = ({ showNav = true, children }) => {
  const { session } = useSession();

  return (
    <section className={styles['home-layout']}>
      <div className={styles['img-wrapper']}>
        <img src="/gannet-bg.jpeg" alt="Gannet.ai background" />
      </div>

      <div
        className={classnames(styles['content'], {
          [styles['show-nav']]: showNav,
        })}
      >
        {showNav && (
          <nav className={styles['nav']}>
            <a className={styles['nav-link']} href="https://app.gannet.ai">
              {session.email ? 'App' : 'Homepage'}
            </a>
            <a
              className={styles['nav-link']}
              href="https://app.gannet.ai/privacy"
            >
              Privacy
            </a>
          </nav>
        )}

        {children}
      </div>
    </section>
  );
};

export default HomeLayout;
