import { FormEvent, useState } from 'react';
import styles from './title.module.scss';
import useThread from '../../hooks/useThread';
import PenToSquareIcon from '@/assets/icons/pen-to-square.svg?react';

const Title = () => {
  const [isEditing, setIsEditing] = useState(false);
  const { id, title, handleTitleUpdate } = useThread();

  const onTitleClick = () => {
    setIsEditing(true);
  };

  const handleInputSubmit = (event: FormEvent) => {
    const newTitle = (event.target as HTMLInputElement).value.trim();
    if (newTitle !== title && newTitle !== '') {
      handleTitleUpdate(newTitle);
    }
    setIsEditing(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleInputSubmit(event);
    } else if (event.key === 'Escape') {
      (event.target as HTMLInputElement).value = title;
      setIsEditing(false);
    }
  };

  return (
    <div className={styles['title-wrapper']}>
      {id &&
        (isEditing ? (
          <input
            data-testid="thread-title-input"
            type="text"
            autoFocus={true}
            defaultValue={title}
            onBlur={handleInputSubmit}
            onSubmit={handleInputSubmit}
            onKeyDown={handleKeyDown}
            maxLength={90}
          />
        ) : (
          <>
            <h1 data-testid="thread-title" onClick={onTitleClick}>
              {title}
            </h1>
            <PenToSquareIcon
              className={styles['pen-icon']}
              onClick={onTitleClick}
            />
          </>
        ))}
    </div>
  );
};

export default Title;
