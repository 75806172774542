import useOutsideClickHandler from '@/hooks/useClickOutside';
import styles from './modal.module.scss';
import XmarkIcon from '@/assets/icons/xmark.svg?react';
import { FC, ReactNode } from 'react';

interface ModalProps {
  children: ReactNode;
  title: string;
  isOpen: boolean;
  onClose: () => void;
}

const Modal: FC<ModalProps> = ({ children, title, isOpen, onClose }) => {
  const closePopover = () => {
    onClose();
  };
  const modalRef = useOutsideClickHandler<HTMLDivElement>(closePopover);

  if (!isOpen) return null;

  return (
    <>
      <div ref={modalRef} className={styles['modal']}>
        <div className={styles['modal-header']}>
          <h2>{title}</h2>
          <XmarkIcon className={styles['close-xmark']} onClick={closePopover} />
        </div>

        <div className={styles['modal-content']}>{children}</div>
      </div>

      <div className={styles['filter-modal-backdrop']}></div>
    </>
  );
};

export default Modal;
