import React, { FC, useCallback, useRef, useState } from 'react';
import PasswordInput from '@/components/PasswordInput';
import styles from './new-password-fields.module.scss';
import CheckIcon from '@/assets/icons/check.svg?react';
import WarningIcon from '@/assets/icons/triangle-exclamation.svg?react';

import { classnames } from '@/utils/functions';

interface PwdRequirement {
  id: string;
  regex?: string;
  message: string;
}

interface NewPasswordFieldsProps {
  label?: string;
}

const NewPasswordFields: FC<NewPasswordFieldsProps> = ({
  label = 'Password',
}) => {
  const [pwd, setPwd] = useState('');
  const [confirmPwd, setConfirmPwd] = useState('');
  const pwdRules = useRef<PwdRequirement[]>([
    {
      id: 'lowercase',
      regex: '(?=.*[a-z])',
      message: 'Have at least one lowercase letter.',
    },
    {
      id: 'uppercase',
      regex: '(?=.*[A-Z])',
      message: 'Have at least one uppercase letter.',
    },
    {
      id: 'number',
      regex: '(?=.*[0-9])',
      message: 'Have at least one number.',
    },
    {
      id: 'special',
      regex: '(?=.*[!@#$&*%\\-_])',
      message:
        'Have at least one of the following special characters: !@#$%&*_-',
    },
    {
      id: 'length',
      regex: '.{8,24}',
      message: 'Have between 8 and 24 characters.',
    },
    {
      id: 'match',
      message: 'The password and confirmation password must match.',
    },
  ]);

  const [rulesPassed, setRulesPassed] = useState<number[]>([]);

  const getPassedRules = useCallback(() => {
    const newPassedRules: number[] = [];
    pwdRules.current.forEach((requirement, index) => {
      if (requirement.id === 'match') return;

      if (requirement.regex && new RegExp(requirement.regex).test(pwd)) {
        newPassedRules.push(index);
      }
    });

    if (pwd !== '' && pwd === confirmPwd) {
      const matchRuleIndex = pwdRules.current.findIndex(
        (req) => req.id === 'match'
      );
      newPassedRules.push(matchRuleIndex);
    }

    return newPassedRules;
  }, [confirmPwd, pwd]);

  const pwdValidator = useCallback(() => {
    const newPassedRules = getPassedRules();
    setRulesPassed(newPassedRules);

    if (pwd === '') {
      return 'Please enter a password.';
    }

    const matchRuleIndex = pwdRules.current.findIndex(
      (req) => req.id === 'match'
    );
    if (confirmPwd !== '' && newPassedRules.indexOf(matchRuleIndex) === -1) {
      return 'Please make sure your password and confirmation password match.';
    } else if (
      newPassedRules.length <
      pwdRules.current.filter((req) => req.id !== 'match').length
    ) {
      return 'Please review the password requirements.';
    } else {
      return '';
    }
  }, [confirmPwd, getPassedRules, pwd]);

  const confirmPwdValidator = useCallback(() => {
    if (confirmPwd === '') {
      return 'Please enter your password again.';
    } else if (pwd === confirmPwd) {
      return '';
    } else {
      const matchRuleIndex = pwdRules.current.findIndex(
        (req) => req.id === 'match'
      );

      return pwdRules.current[matchRuleIndex].message;
    }
  }, [confirmPwd, pwd]);

  return (
    <section className={styles['new-password-fields']}>
      <div className={styles['password-requirements']}>
        <p>
          <strong>Your password must:</strong>
        </p>
        <ul>
          {pwdRules.current.map((req, i) => (
            <li
              key={i}
              className={classnames({
                [styles['passed']]: rulesPassed.includes(i),
              })}
            >
              {rulesPassed.includes(i) ? <CheckIcon /> : <WarningIcon />}
              {req.message}
            </li>
          ))}
        </ul>
      </div>
      <PasswordInput
        password={pwd}
        label={label}
        setPassword={setPwd}
        autoFocus={false}
        customPasswordValidations={pwdValidator}
      />
      <PasswordInput
        id="confirm_password"
        label={`Confirm ${label}`}
        password={confirmPwd}
        setPassword={setConfirmPwd}
        customPasswordValidations={confirmPwdValidator}
      />
    </section>
  );
};

export default NewPasswordFields;
