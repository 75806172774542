import { Helmet } from 'react-helmet-async';
import Button from '../Button';
import useSession from '@/hooks/useSession';
import { FC } from 'react';
import CommentMedicalIcon from '@/assets/icons/comment-medical.svg?react';
import styles from './feedback-cta.module.scss';
interface FeedbackButtonProps {
  useLink?: boolean;
  text?: string;
}
const FeedbackCTA: FC<FeedbackButtonProps> = ({
  text = 'Share Feedback',
  useLink = false,
}) => {
  const { session, pushUserEvent } = useSession();
  const id = 'mR4lrl';
  const layout = 'modal';
  const width = 600;
  const emojiText = '👋';
  const emojiAnimation = 'wave';
  const autoClose = 10000;
  return (
    <>
      <Helmet>
        <script async src="https://tally.so/widgets/embed.js"></script>
      </Helmet>

      {useLink ? (
        <a
          className={styles['feedback-cta-link']}
          href={`#tally-open=${id}&tally-layout=${layout}&tally-width=${width}&tally-emoji-text=${emojiText}&tally-emoji-animation=${emojiAnimation}&tally-auto-close=${autoClose}&email=${session.email}`}
          onClick={() => {
            pushUserEvent('click_feedback_link');
          }}
        >
          {text}
        </a>
      ) : (
        <Button
          className={styles['feedback-cta-btn']}
          variation="tertiary"
          data-tally-open={id}
          data-tally-layout={layout}
          data-tally-width={width}
          data-tally-emoji-text={emojiText}
          data-tally-emoji-animation={emojiAnimation}
          data-email={session.email}
          onClick={() => {
            pushUserEvent('click_feedback_btn');
          }}
        >
          <CommentMedicalIcon />
          {text}
        </Button>
      )}
    </>
  );
};

export default FeedbackCTA;
