import useThread from '../../hooks/useThread';
import styles from './conversation.module.scss';
import useConversationScroll from '../../hooks/useConversationScroll';
import Block from '../Block';
import { BlockProvider } from '../../context/BlockProvider';
import { useState } from 'react';
import ChevronLeftIcon from '@/assets/icons/chevron-left.svg?react';
import { classnames } from '@/utils/functions';

const Conversation = () => {
  const [canScrollTop, setCanScrollTop] = useState(false);
  const [canScrollBottom, setCanScrollBottom] = useState(false);
  const { status, blocks, id } = useThread();
  const messagesRef = useConversationScroll<HTMLDivElement>(
    blocks.length > 0 ? (blocks[blocks.length - 1]?.id as string) : '',
    id as string,
    status === 'FETCHING' || status === 'TYPING'
  );

  const handleScroll = () => {
    if (!messagesRef.current) return;
    const { scrollTop, scrollHeight, clientHeight } = messagesRef.current;
    const threshold = 10;
    setCanScrollBottom(scrollTop <= scrollHeight - clientHeight - threshold);
    setCanScrollTop(scrollTop > threshold);
  };

  const scrollToBottom = () => {
    if (!messagesRef.current) return;
    messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
  };

  return (
    <div
      className={classnames(styles['conversation-wrapper'], {
        [styles['can-scroll-bottom']]: canScrollBottom,
        [styles['can-scroll-top']]: canScrollTop,
      })}
    >
      <div
        data-testid="conversation"
        ref={messagesRef}
        className={classnames(styles['conversation'], status.toLowerCase())}
        onScroll={handleScroll}
      >
        {blocks.map((block, i) => (
          <BlockProvider
            key={block.id}
            threadID={id}
            block={block}
            isLast={i === blocks.length - 1}
          >
            <Block />
          </BlockProvider>
        ))}
      </div>
      {canScrollBottom && status === 'READY' && (
        <button
          onClick={scrollToBottom}
          className={styles['back-to-bottom']}
          title="Go back to the bottom"
        >
          <ChevronLeftIcon />
        </button>
      )}
    </div>
  );
};

export default Conversation;
