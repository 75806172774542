import { FC, ReactNode } from 'react';
import styles from './input-wrapper.module.scss';
import { classnames } from '@/utils/functions';
interface InputWrapperProps {
  className?: string;
  hideRequiredStyle?: boolean;
  children: ReactNode;
}

const InputWrapper: FC<InputWrapperProps> = ({
  hideRequiredStyle = false,
  className,
  children,
}) => {
  return (
    <div
      className={classnames(styles['input-wrapper'], {
        [styles['hide-required-style']]: hideRequiredStyle,
        [className as string]: !!className,
      })}
    >
      {children}
    </div>
  );
};

export default InputWrapper;
