import { AWS_LLM_API } from '@/config/aws';
import { PromptUsage } from '@/models/session';
import { extractPayload, getSubHeader } from '@/utils/api';
import { get } from 'aws-amplify/api';

interface LimitsResponse {
  success: boolean;
  message: string;
  data: {
    userData: {
      cognito_sub: string;
      name: string;
      email: string;
      subscription: {
        name: string;
        data_period: number;
        data_period_unit: string;
        prompt_limits: {
          per_day: number;
          per_month: number;
        };
        subscription_id: string;
      };
    };
    countData: {
      today: number;
      thisMonth: number;
    };
  };
}
const fetchPromptUsage = async (userSub: string): Promise<PromptUsage> => {
  const { response } = get({
    apiName: AWS_LLM_API,
    path: '/v1/base/conversation/limits',
    options: {
      headers: getSubHeader(userSub),
    },
  });

  const payload = await extractPayload<LimitsResponse>(response);

  const usage = {
    perDay: payload.data.userData.subscription.prompt_limits.per_day,
    perMonth: payload.data.userData.subscription.prompt_limits.per_month,
    usedToday: payload.data.countData.today,
    usedThisMonth: payload.data.countData.thisMonth,
    remainingToday: 0,
    remainingThisMonth: 0,
  };
  usage.remainingToday = usage.perDay - usage.usedToday;
  usage.remainingThisMonth = usage.perMonth - usage.usedThisMonth;

  return usage;
};

export default fetchPromptUsage;
