export const getSubHeader = (userID: string): Record<string, string> => {
  return {
    'x-user-sub': userID,
  };
};

export const extractPayload = async <T>(
  promise: Promise<unknown>
): Promise<T> => {
  const response = (await promise) as { body: Response };
  return await response.body.json();
};
