import { AWS_USERS_API } from '@/config/aws';
import { APIUser, User } from '@/models/user';
import { extractPayload } from '@/utils/api';
import { get } from 'aws-amplify/api';

interface WaitlistUsersResponse {
  data: {
    userWaitlistData: APIUser[];
  };
}
interface UsersResponse {
  data: APIUser[];
}

const fetchUsers = async (): Promise<User[]> => {
  let users: User[] = [];
  const options = {
    queryParams: {
      page: '1',
      perPage: '100',
    },
  };
  const { response: waitlistRequest } = get({
    apiName: AWS_USERS_API,
    path: '/user-admin/user-waitlist',
    options,
  });
  const { response: acceptedUsersRequest } = get({
    apiName: AWS_USERS_API,
    path: '/user-admin/users',
    options,
  });
  const waitlistPayload =
    await extractPayload<WaitlistUsersResponse>(waitlistRequest);
  const acceptedUsersPayload =
    await extractPayload<UsersResponse>(acceptedUsersRequest);

  if (!waitlistPayload) {
    throw new Error('No response payload');
  } else {
    const waitlistUsers = parseAPIUserList(
      waitlistPayload.data.userWaitlistData
    );

    const acceptedUsers = parseAPIUserList(acceptedUsersPayload.data);

    users = [...waitlistUsers, ...acceptedUsers];
  }

  return users;
};

const parseAPIUserList = (data: APIUser[]): User[] => {
  return data.map((user: APIUser) => {
    const isWaitlist = user.user_waitlist_id ? true : false;
    return {
      id: (isWaitlist ? user.user_waitlist_id : user.user_id) as string,
      email: (isWaitlist ? user.user_email : user.email) as string,
      name: (isWaitlist ? user.user_name : user.name) as string,
      organization: (isWaitlist
        ? user.user_organization
        : user.organization) as string,
      useCase: (isWaitlist ? user.use_case : '') as string,
      status: (isWaitlist ? user.waitlist_status : user.status) as string,
      date: new Date(user.created_at),
    };
  }) as User[];
};

export default fetchUsers;
