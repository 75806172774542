import { useEffect, useState } from 'react';

const useResponsive = (
  onMobile?: () => void,
  onDesktop?: () => void
): { isMobile: boolean } => {
  const checkIfIsMobile = () => window.innerWidth <= 768;
  const [isMobile, setIsMobile] = useState(checkIfIsMobile);
  useEffect(() => {
    const handleResize = () => {
      const isCurrentlyMobile = checkIfIsMobile();
      if (isMobile !== isCurrentlyMobile) {
        if (isCurrentlyMobile && onMobile) {
          onMobile();
        } else if (!isCurrentlyMobile && onDesktop) {
          onDesktop();
        }
        setIsMobile(isCurrentlyMobile);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile, onDesktop, onMobile]);

  return { isMobile };
};

export default useResponsive;
