import Button from '@/components/Button';
import Form from '@/components/Form';
import SEOHelmet from '@/components/SEOHelmet';
import useSession from '@/hooks/useSession';
import { useState } from 'react';

const Account = () => {
  const { session } = useSession();

  const [user, setUser] = useState(session.email);
  const [email, setEmail] = useState('');

  const handleSubmit = async () => {};

  return (
    <div>
      <SEOHelmet title="Accout Details" index={false} />
      <h1>Account</h1>
      <Form onSubmit={handleSubmit}>
        <label htmlFor="username">Name:</label>
        <input
          type="text"
          id="name"
          name="name"
          autoComplete="off"
          autoFocus={true}
          onChange={(e) => setUser(e.target.value)}
          value={user}
          required
        />

        <label htmlFor="username">Name:</label>
        <input
          type="text"
          id="name"
          name="name"
          autoComplete="off"
          autoFocus={true}
          onChange={(e) => setUser(e.target.value)}
          value={user}
          required
        />

        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          name="email"
          autoComplete="off"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          required
        />

        <Button variation="alt">Update Info</Button>
      </Form>
    </div>
  );
};

export default Account;
