import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import styles from './input-box.module.scss';
import useThread from '../../pages/Thread/hooks/useThread';
import SendIcon from '@/assets/icons/send.svg?react';

import InputFilters from '../InputFilters';
import { classnames, getTodayStr } from '@/utils/functions';

interface InputBoxProps {
  showFilters?: boolean;
}

const InputBox: FC<InputBoxProps> = ({ showFilters = false }) => {
  const [selectedCountries, setSelectedCountries] = useState<string[]>([]);
  const [selectedSources, setSelectedSources] = useState<string[]>([]);
  const [earliestDate, setEarliestDate] = useState<string>('');
  const [latestDate, setLatestDate] = useState<string>(getTodayStr());

  const promptRef = useRef<HTMLTextAreaElement>(null);
  const [prompt, setPrompt] = useState('');
  const { status, handlePromptSubmit, id } = useThread();
  const disableSubmit = prompt.trim() === '' || status !== 'READY';

  useEffect(() => {
    if (promptRef.current) {
      promptRef.current.focus();
    }
  }, [id]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLSpanElement>) => {
    if (
      !(event.shiftKey || event.ctrlKey || event.metaKey) &&
      event.key === 'Enter' &&
      !disableSubmit
    ) {
      event.preventDefault();
      handleSubmit();
    }
  };

  const resetInputHeight = () => {
    if (!promptRef.current) return;

    promptRef.current.style.height = '0px';
    const scrollHeight = promptRef.current.scrollHeight;
    promptRef.current.style.height = scrollHeight + 4 + 'px';
  };
  const onPromptChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setPrompt(event.target.value);
    resetInputHeight();
  };

  const resetPrompt = () => {
    setPrompt('');
    setTimeout(() => {
      resetInputHeight();
    }, 20);
  };

  const handleSubmit = () => {
    if (disableSubmit || !promptRef.current) return;
    promptRef.current.blur();
    resetPrompt();
    let filters = undefined;

    if (showFilters) {
      filters = {
        countries: selectedCountries,
        sources: selectedSources,
        earliestDate,
        latestDate,
      };
    }
    handlePromptSubmit(promptRef.current.value, filters);
  };
  return (
    <div
      className={classnames(styles['input-box'], {
        [styles['show-filters']]: showFilters,
      })}
    >
      {showFilters && (
        <InputFilters
          selectedCountries={selectedCountries}
          selectedSources={selectedSources}
          earliestDate={earliestDate}
          latestDate={latestDate}
          setSelectedCountries={setSelectedCountries}
          setSelectedSources={setSelectedSources}
          setEarliestDate={setEarliestDate}
          setLatestDate={setLatestDate}
        />
      )}

      <textarea
        data-testid="input-box"
        ref={promptRef}
        value={prompt}
        className={styles['prompt']}
        onChange={onPromptChange}
        rows={1}
        tabIndex={0}
        autoFocus={true}
        onKeyDown={handleKeyDown}
        placeholder="Type a message..."
      ></textarea>
      <button
        data-testid="submit-prompt"
        className={styles['submit-prompt']}
        onClick={handleSubmit}
        disabled={disableSubmit}
      >
        <SendIcon />
      </button>
    </div>
  );
};

export default InputBox;
