import useSession from '@/hooks/useSession';
import styles from './threads-list.module.scss';
import { Link } from 'react-router-dom';

const ThreadsList = () => {
  const { session, pushUserEvent } = useSession();
  return (
    <ul className={styles['threads-list']}>
      {session.threads.length > 0 && (
        <>
          {session.threads.map(({ blocks, id, title }, i) => {
            const lastMessage = blocks[blocks.length - 1];

            return (
              <li key={i}>
                <Link
                  data-testid="thread-list-item"
                  to={`/app/thread/${id}`}
                  onClick={() => {
                    pushUserEvent('click_thread');
                  }}
                >
                  {title}
                  {lastMessage && (
                    <span>
                      Last:{'  '}
                      {lastMessage.queryDate.toLocaleString()}
                    </span>
                  )}
                </Link>
              </li>
            );
          })}
        </>
      )}
    </ul>
  );
};

export default ThreadsList;
