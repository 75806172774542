import { useRef, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Form from '@/components/Form';
import FormMessage from '@/components/FormMessage';
import Button from '@/components/Button';
import HomeLayout from '@/layouts/HomeLayout';
import SEOHelmet from '@/components/SEOHelmet';
import { ErrorException } from '@/models/error';
import { gaPushEvent } from '@/utils/analytics';
import useSession from '@/hooks/useSession';
import InputWrapper from '@/components/Form/InputWrapper';
import { resetPassword } from 'aws-amplify/auth';

const ForgotPassword = () => {
  const location = useLocation();

  const userRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const { setSession } = useSession();

  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(location.state?.email || '');
  const [errMsg, setErrMsg] = useState('');

  useEffect(() => {
    if (userRef.current) userRef.current.focus();
  }, [location.state]);

  useEffect(() => {
    setErrMsg('');
  }, [user]);

  const requestPasswordReset = async () => {
    try {
      setLoading(true);
      const { nextStep } = await resetPassword({ username: user });
      if (nextStep.resetPasswordStep !== 'CONFIRM_RESET_PASSWORD_WITH_CODE') {
        throw new Error('Unexpected response');
      }
      gaPushEvent('recovery_password_attempt');
      setSession((current) => ({
        ...current,
        email: user,
      }));
      navigate('/reset-password', { state: { forgotPassword: true } });
    } catch (err) {
      const error = err as ErrorException;
      console.log(error);

      if (error.name === 'UserNotFoundException') {
        setErrMsg(
          'The specified email address is not associated with an existing account.'
        );
        gaPushEvent('error_forgot_password_user_not_found');
      } else {
        setErrMsg(
          'An errror happened. Please contact us directly at info@gannet.ai'
        );
        gaPushEvent('error_forgot_password_unknown');
      }
      setLoading(false);
    }
  };

  return (
    <HomeLayout>
      <SEOHelmet
        title="Reset Password"
        description="Reset Password for GANNET"
        index={false}
      />
      <Form onSubmit={() => requestPasswordReset()}>
        <InputWrapper>
          <input
            type="text"
            id="username"
            name="username"
            ref={userRef}
            autoComplete="off"
            onChange={(e) => setUser(e.target.value)}
            value={user}
            required
          />
          <label htmlFor="username">Email:</label>
        </InputWrapper>
        <Button variation="alt" loading={loading}>
          Recover Password
        </Button>
      </Form>
      <p>
        Go back to <a href="https://app.gannet.ai/sign-in">Sign In</a>
      </p>

      <FormMessage type="error" message={errMsg} />
    </HomeLayout>
  );
};

export default ForgotPassword;
