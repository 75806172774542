import { ButtonHTMLAttributes, FC, ReactNode } from 'react';
import styles from './button.module.scss';
import Spinner from '@/assets/icons/spinner.svg?react';

import { classnames } from '@/utils/functions';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
  loadingText?: string;
  className?: string;
  variation?: 'secondary' | 'tertiary' | 'alt';
  children: ReactNode;
}

const Button: FC<ButtonProps> = ({
  children,
  variation,
  className,
  loading,
  loadingText = 'Loading',
  ...props
}) => {
  return (
    <button
      className={classnames(styles['button'], {
        [styles[variation as string]]: !!variation,
        [className as string]: !!className,
        [styles['loading']]: !!loading,
      })}
      data-status={loading ? 'loading' : 'ready'}
      {...props}
    >
      {loading ? (
        <>
          {loadingText}...
          <Spinner className={styles['spinner']} />
        </>
      ) : (
        children
      )}
    </button>
  );
};

export default Button;
