import styles from './sidebar.module.scss';
import { classnames } from '@/utils/functions';
import TableOfContents from '../TableOfContents';
import Bibliography from '../Bibliography';
import useSidebar from '../../hooks/useSidebar';
import ThreadFilters from '../ThreadFilters';

const Sidebar = () => {
  const { showOnMobile } = useSidebar();

  return (
    <aside
      className={classnames(styles['sidebar'], {
        [styles['show-on-mobile']]: showOnMobile,
      })}
    >
      <div className={classnames(styles['sidebar-item'], styles['filters'])}>
        <h2>Filters</h2>
        <ThreadFilters />
      </div>
      <div className={styles['sidebar-item']}>
        <h2>Table of Contents</h2>
        <TableOfContents />
      </div>
      <div className={styles['sidebar-item']}>
        <h2>Bibliography</h2>
        <Bibliography />
      </div>
    </aside>
  );
};

export default Sidebar;
