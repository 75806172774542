export enum Role {
  USER = 2001,
  ADMIN = 5150,
}
export type UserStatus = 'WAITING' | 'ACTIVE';

export interface APIUser {
  user_waitlist_id?: string;
  user_id?: string;
  user_email?: string;
  email?: string;
  name?: string;
  user_name?: string;
  organization?: string;
  user_organization?: string;
  use_case?: string;
  status?: string;
  waitlist_status?: string;
  created_at: string;
}

export interface User {
  id: string;
  email: string;
  name: string;
  organization: string;
  useCase: string;
  status: UserStatus;
  date: Date;
}
