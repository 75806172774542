import { AWS_LLM_API } from '@/config/aws';
import { extractPayload, getSubHeader } from '@/utils/api';
import { post } from 'aws-amplify/api';

const addMessageFeedback = async (
  sub: string,
  messageID: string,
  reason: string,
  details: string
): Promise<void> => {
  const { response } = post({
    apiName: AWS_LLM_API,
    path: `/v1/base/conversation/messages/${messageID}/feedback`,
    options: {
      headers: getSubHeader(sub),
      body: { reason, detailed_reason: details },
    },
  });

  const payload = await extractPayload(response);

  console.log(payload);
};

export default addMessageFeedback;
