export type GTagEventData = {
  value?: number;
};

interface GTag {
  (command: 'config', targetId: string, config?: object): void;
  (command: 'set', config: object): void;
  (command: 'event', action: string, event?: GTagEventData): void;
}

declare global {
  interface Window {
    gtag: GTag;
  }
}

export const isGADisabled = () =>
  !import.meta.env.VITE_GA_MEASUREMENT_ID || window.gtag === undefined;

export const gaPushEvent = (
  action: string,
  email: string = '',
  data: GTagEventData = {}
): void => {
  if (isGADisabled()) return;
  try {
    let eventName = action;

    if (email !== '' && email.indexOf('@datafriendlyspace.org') !== -1) {
      eventName = `internal_${eventName}`;
    }
    window.gtag('event', eventName, data);
  } catch (e) {
    console.error(e);
  }
};

export const gaIdentifyUser = (sub: string) => {
  if (isGADisabled() || !sub) return;
  try {
    window.gtag('set', {
      user_id: sub,
    });
  } catch (e) {
    console.error(e);
  }
};
