import useThread from '../../hooks/useThread';
import styles from './thread-options.module.scss';
import useSidebar from '../../hooks/useSidebar';

const ThreadOptions = () => {
  const { id } = useThread();
  const { showOnMobile, toggleShowOnMobile } = useSidebar();

  if (!id) return null;

  // const onShare = () => {};

  // const onExport = () => {};

  // const onDelete = () => {
  //   const confirmDelete = confirm(
  //     'Are you sure you want to delete this thread?'
  //   );
  //   if (confirmDelete) {
  //     // deleteThread(auth.email, id);
  //     refreshThreads();
  //     navigate('/app', { replace: true });
  //   }
  // };

  return (
    <div className={styles['thread-options']}>
      {/* <button className={styles['trash-btn']} onClick={onDelete}>
        <i>🗑️</i>
        Delete
      </button> */}
      <button className={styles['show-details']} onClick={toggleShowOnMobile}>
        {showOnMobile ? 'Hide' : 'Show'} details
      </button>
    </div>
  );
};

export default ThreadOptions;
