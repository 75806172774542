import { ErrorException } from '@/models/error';
import { getCurrentUser } from 'aws-amplify/auth';

const getSession = async (): Promise<
  { email: string; sub: string } | undefined
> => {
  try {
    const { username: email, userId: sub } = await getCurrentUser();

    return { email, sub };
  } catch (err) {
    if ((err as ErrorException).name !== 'UserUnAuthenticatedException') {
      console.error(err);
    }

    return undefined;
  }
};

export default getSession;
