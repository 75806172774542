import Button from '@/components/Button';
import Form from '@/components/Form';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import HomeLayout from '@/layouts/HomeLayout';
import SEOHelmet from '@/components/SEOHelmet';
import FormMessage from '@/components/FormMessage';
import useSession from '@/hooks/useSession';
import {
  confirmResetPassword,
  fetchUserAttributes,
  resetPassword,
  signIn,
} from 'aws-amplify/auth';
import { ErrorException } from '@/models/error';
import { createUserAuth } from '@/utils/session';
import { gaIdentifyUser } from '@/utils/analytics';
import InputWrapper from '@/components/Form/InputWrapper';
import NewPasswordFields from '@/components/NewPasswordFields';

const ResetPassword = () => {
  const { session, pushUserEvent, setSession } = useSession();
  const { email } = session;
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const navigate = useNavigate();
  useEffect(() => {
    setErrMsg('');
  }, [code]);

  useEffect(() => {
    if (email === '') {
      navigate('/sign-in', { replace: true });
    }
  }, [email, navigate]);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const pwd = formData.get('password') as string;

    try {
      setLoading(true);

      await confirmResetPassword({
        username: email,
        confirmationCode: code,
        newPassword: pwd,
      });

      await signIn({
        username: email,
        password: pwd,
        options: {
          authFlowType: 'USER_PASSWORD_AUTH',
        },
      });

      const sub = ((await fetchUserAttributes()) as { sub: string }).sub;

      gaIdentifyUser(sub);

      const newSession = await createUserAuth(email, sub);
      setSession(newSession);
      pushUserEvent('reset_password');
      navigate('/app');
    } catch (err) {
      const error = err as ErrorException;

      if (error.name === 'CodeMismatchException') {
        setErrMsg('The verification code is incorrect.');
      } else if (error.name === 'ExpiredCodeException') {
        setErrMsg(
          'The code expired. a new code has been sent to your email. Please check your inbox and spam folder.'
        );
        await resetPassword({ username: email });
      } else {
        setErrMsg(
          'An error happened. please try again in an hour or contact support if the error persists.'
        );
      }
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <HomeLayout>
      <SEOHelmet
        title="Reset Password"
        description="Assign a new password to your account."
        index={false}
      />
      <h1>Reset Password</h1>
      <p>
        You're almost done! We sent you a confirmation email to "<i>{email}</i>"
        with a <strong>verification code</strong>. Make sure to check both your
        inbox and your SPAM folder just in case.
      </p>

      <Form onSubmit={handleSubmit}>
        <InputWrapper>
          <input
            type="text"
            id="code"
            name="code"
            value={code}
            required
            autoFocus={true}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setCode(e.target.value)
            }
          />
          <label htmlFor="code">Verification code:</label>
        </InputWrapper>
        <NewPasswordFields label="New Password" />
        <Button variation="alt" loading={loading}>
          RESET PASSWORD
        </Button>
      </Form>
      <FormMessage type="error" message={errMsg} />
    </HomeLayout>
  );
};

export default ResetPassword;
