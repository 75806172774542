export const AWS_USERS_API = 'main';
export const AWS_LLM_API = 'llm';

export const AMPLIFY_CONFIG = {
  Auth: {
    Cognito: {
      userPoolId: import.meta.env.VITE_AUTH_USER_POOL_ID,
      userPoolClientId: import.meta.env.VITE_AUTH_CLIENT_ID,
      identityPoolId: import.meta.env.VITE_AUTH_IDENTITY_POOL_ID,
    },
  },
  API: {
    REST: {
      [AWS_USERS_API]: {
        endpoint: `${import.meta.env.VITE_BACKEND_ENDPOINT_USERS}/${
          import.meta.env.VITE_BACKEND_STAGE
        }/v1`,
        region: import.meta.env.VITE_AUTH_REGION,
      },
      [AWS_LLM_API]: {
        endpoint: `${import.meta.env.VITE_BACKEND_ENDPOINT_LLM}/${
          import.meta.env.VITE_BACKEND_STAGE
        }`,
        region: import.meta.env.VITE_AUTH_REGION,
      },
    },
  },
};
