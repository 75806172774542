import { useEffect, useRef } from 'react';

const useConversationScroll = <T extends HTMLElement>(
  scrollBottomTrigger: string,
  animateTrigger: string,
  shouldObserve: boolean
) => {
  const elementRef = useRef<T>(null);
  const observer = useRef<ResizeObserver | null>(null);

  const scrollToBottom = (element: T, animate = false) => {
    if (animate) {
      if (element.style.opacity) {
        return;
      }
      element.style.opacity = '0';
      element.style.transition = '';
    }

    element.style.scrollBehavior = 'auto';

    setTimeout(() => {
      if (!element) return;
      element.scrollTop = element.scrollHeight;
      element.style.scrollBehavior = '';
      if (animate) {
        element.style.transition = 'opacity 500ms ease';
        element.style.opacity = '1';
      }
    }, 100);
  };

  useEffect(() => {
    if (!elementRef.current) return;
    scrollToBottom(elementRef.current, true);

    return () => {
      if (elementRef.current && animateTrigger !== undefined) {
        elementRef.current.style.opacity = '';
        elementRef.current.style.transition = '';
      }
    };
  }, [animateTrigger]);

  useEffect(() => {
    if (!elementRef.current) return;

    scrollToBottom(elementRef.current, false);
  }, [scrollBottomTrigger]);

  // This useEffect is used to scroll to the bottom of the chat window when a new message is received
  useEffect(() => {
    if (!elementRef.current) return;

    const calcShouldScroll = (parentEl: Element | null) => {
      if (!parentEl) return false;
      const { scrollHeight, scrollTop, clientHeight } = parentEl;
      const sum = scrollTop + clientHeight + 300;

      return scrollHeight > clientHeight && scrollHeight < sum;
    };

    if (shouldObserve) {
      elementRef.current.style.scrollBehavior = 'smooth';

      if (observer.current) {
        observer.current.disconnect();
      }

      observer.current = new ResizeObserver(() => {
        if (calcShouldScroll(elementRef.current)) {
          if (!elementRef?.current) {
            return;
          }

          elementRef.current.scrollTop = elementRef.current.scrollHeight;
        }
      });

      observer.current.observe(elementRef.current.lastChild as Element);
    } else if (observer.current) {
      observer.current.disconnect();
    }
  }, [elementRef, shouldObserve]);

  return elementRef;
};

export default useConversationScroll;
