import useOutsideClickHandler from '@/hooks/useClickOutside';
import useResponsive from '@/hooks/useResponsive';
import styles from './filter-modal.module.scss';
import XmarkIcon from '@/assets/icons/xmark.svg?react';
import MultiOptionInput from '../../MultiOptionInput';
import { Dispatch, FC, SetStateAction } from 'react';
import { getTodayStr } from '@/utils/functions';
import { FilterModalOptions } from '../utils';
// @TODO Merge with @/components/Modal

interface FilterModalProps {
  countries: string[];
  sources: string[];
  showOptions: string | null;
  setShowOptions: Dispatch<SetStateAction<FilterModalOptions>>;
  selectedCountries: string[];
  selectedSources: string[];
  earliestDate: string;
  latestDate: string;
  isSingleDate: boolean;
  setIsSingleDate: Dispatch<SetStateAction<boolean>>;
  setSelectedCountries: Dispatch<SetStateAction<string[]>>;
  setSelectedSources: Dispatch<SetStateAction<string[]>>;
  setEarliestDate: Dispatch<SetStateAction<string>>;
  setLatestDate: Dispatch<SetStateAction<string>>;
}
const FilterModal: FC<FilterModalProps> = ({
  countries,
  sources,
  showOptions,
  setShowOptions,
  selectedCountries,
  selectedSources,
  setSelectedCountries,
  setSelectedSources,
  isSingleDate,
  setIsSingleDate,
  earliestDate,
  latestDate,
  setEarliestDate,
  setLatestDate,
}) => {
  const closePopover = () => {
    setShowOptions(null);
  };
  const { isMobile } = useResponsive();
  const modalRef = useOutsideClickHandler<HTMLDivElement>(closePopover);

  if (showOptions === null) return null;

  const today = getTodayStr();
  const shouldShowOnDesktop = !isMobile && showOptions !== null;
  const onCheckboxChange = (name: string, value: string, selected: boolean) => {
    let selectedItems: string[] = [];
    let updateFn;

    if (name === 'countries') {
      selectedItems = [...selectedCountries];
      updateFn = setSelectedCountries;
    } else if (name === 'sources') {
      selectedItems = [...selectedSources];
      updateFn = setSelectedSources;
    } else {
      return;
    }

    if (selected) {
      selectedItems.push(value);
    } else {
      selectedItems = selectedItems.filter((item) => item !== value);
    }

    updateFn(selectedItems);
  };

  return (
    <>
      <div ref={modalRef} className={styles['filter-modal']}>
        <XmarkIcon className={styles['close-xmark']} onClick={closePopover} />
        {(showOptions === 'date' || shouldShowOnDesktop) && (
          <div className={styles['date-wrapper']}>
            <strong>Publish Dates:</strong>
            <div className={styles['date-type']}>
              <input
                type="radio"
                id="singleDate"
                name="dateSelection"
                value="singleDate"
                checked={isSingleDate}
                onChange={(event) => setIsSingleDate(event.target.checked)}
              />
              <label htmlFor="singleDate">Single Date</label>
              <input
                type="radio"
                id="dateInterval"
                name="dateSelection"
                checked={!isSingleDate}
                onChange={(event) => setIsSingleDate(!event.target.checked)}
              />
              <label htmlFor="dateInterval">Date Interval</label>
            </div>

            <div className={styles['date-picker']}>
              {!isSingleDate && (
                <input
                  type="date"
                  value={earliestDate}
                  max={latestDate}
                  onChange={(event) => {
                    setEarliestDate(event.target.value);
                  }}
                />
              )}

              {!isSingleDate && <span>to</span>}
              <input
                type="date"
                value={latestDate}
                min={isSingleDate ? '' : earliestDate}
                max={today}
                onChange={(event) => {
                  setLatestDate(event.target.value || today);
                }}
              />
            </div>
          </div>
        )}
        {(showOptions === 'countries' || shouldShowOnDesktop) && (
          <MultiOptionInput
            options={countries}
            selectedOptions={selectedCountries}
            name="countries"
            handleChange={onCheckboxChange}
          />
        )}
        {(showOptions === 'sources' || shouldShowOnDesktop) && (
          <MultiOptionInput
            options={sources}
            selectedOptions={selectedSources}
            name="sources"
            handleChange={onCheckboxChange}
          />
        )}

        <button className={styles['close-btn']} onClick={closePopover}>
          Close
        </button>
      </div>

      <div className={styles['filter-modal-backdrop']}></div>
    </>
  );
};

export default FilterModal;
