import { useNavigate } from 'react-router-dom';
import styles from './user-options.module.scss';
import useSession from '@/hooks/useSession';
import useLogout from '@/hooks/useLogout';
import DoorOpenIcon from '@/assets/icons/door-open-solid.svg?react';
import CircleInfoIcon from '@/assets/icons/circle-info.svg?react';

import { createPortal } from 'react-dom';
import Modal from '@/components/Modal/modal';
import { useState } from 'react';
import EmailLink from '@/components/EmailLink';
import FeedbackCTA from '@/components/FeedbackCTA';
import { hasThreadsAccess } from '@/utils/session';

const UserOptions = ({ collapsed = false }) => {
  const {
    session: { email, promptUsage, roles },
    pushUserEvent,
  } = useSession();

  const [openInfo, setOpenInfo] = useState(false);
  const logout = useLogout();
  const navigate = useNavigate();

  const handleLogOut = async () => {
    await logout();

    navigate('/');
    window.location.reload();
  };
  return (
    <div className={styles['user-options']}>
      {!collapsed && hasThreadsAccess(roles) && (
        <div className={styles['user-summary']}>
          <strong>
            Prompt Usage{' '}
            <CircleInfoIcon
              className={styles['usage-info']}
              onClick={() => {
                setOpenInfo(true);
                pushUserEvent('click_limit_info');
              }}
            />
          </strong>
          <p>
            <i>Today</i> <span>{promptUsage.usedToday} </span> /{' '}
            <span>{promptUsage.perDay}</span>
          </p>
          <p>
            <i>Current Month</i>
            <span>{promptUsage.usedThisMonth} </span> /{' '}
            <span>{promptUsage.perMonth}</span>
          </p>
        </div>
      )}
      {!collapsed && (
        <div className={styles['user-details']}>
          <p className={styles['user-email']}>
            {email.split('@').map((text, index) => (
              <span className={styles['user-email-part']} key={index}>
                {index === 1 && '@'}
                {text}
              </span>
            ))}
          </p>
        </div>
      )}
      <FeedbackCTA text={collapsed ? '' : 'Share Feedback'} />
      <button className={styles['logout']} onClick={handleLogOut}>
        <DoorOpenIcon />
        {!collapsed && 'Log out'}
      </button>

      {createPortal(
        <Modal
          title="Prompt Usage"
          isOpen={openInfo}
          onClose={() => {
            setOpenInfo(false);
          }}
        >
          <p>
            Prompt usage is a helpful measure to keep track of how many
            interactions you have remaining with our AI assistant for the day
            and the month for the <strong>BETA testing phase</strong>.
          </p>

          <ul className={styles['modal-list']}>
            <li>
              Today,
              {promptUsage.remainingToday === 0 ? (
                <>
                  {' '}
                  you've used all your{' '}
                  <strong>{promptUsage.perDay} prompts</strong>.
                </>
              ) : (
                <>
                  you still have{' '}
                  <strong>
                    {promptUsage.remainingToday} prompt
                    {promptUsage.remainingToday === 1 ? '' : 's'}
                  </strong>{' '}
                  remaining out of <span>{promptUsage.perDay}</span>.
                </>
              )}
            </li>
            <li>
              {promptUsage.remainingThisMonth === 0 ? (
                `
                You've used all your {promptUsage.perMonth} prompts for the month. Please wait until the next month to get more prompts.
              `
              ) : (
                <>
                  For the month, you still have{' '}
                  <strong>
                    {promptUsage.remainingThisMonth} prompt
                    {promptUsage.remainingThisMonth === 1 ? '' : 's'} left.
                  </strong>{' '}
                  remaining out of <span>{promptUsage.perMonth}</span>.
                </>
              )}
            </li>
          </ul>
          <p>
            Remember, these counts reset at the beginning of each day (GMT
            timezone) and at the start of every month.
          </p>
          <p>
            If you need more prompts or have follow up questions, don't hesitate
            to contact us at <EmailLink />.
          </p>
        </Modal>,
        document.body
      )}
    </div>
  );
};

export default UserOptions;
