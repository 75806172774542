import Disclaimer from '@/components/Disclaimer';
import InputBox from '@/components/InputBox';
import styles from './app-home.module.scss';
import Suggestions from '../Thread/components/Suggestions';

const AppHome = () => {
  return (
    <section className={styles['app-home']}>
      <h1>Soar above crisis with insight</h1>
      <Suggestions />
      <InputBox showFilters={true} />
      <Disclaimer />
    </section>
  );
};

export default AppHome;
