import { DEFAULT_SESSION } from '@/models/session';
import useSession from './useSession';
import { signOut } from 'aws-amplify/auth';

const useLogout = () => {
  const { pushUserEvent, setSession } = useSession();

  const logout = async () => {
    try {
      await signOut();
      pushUserEvent('sign_out');

      setSession(DEFAULT_SESSION);
    } catch (err) {
      console.error(err);
    }
  };

  return logout;
};

export default useLogout;
