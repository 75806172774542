import { classnames } from '@/utils/functions';
import useThread from '../../hooks/useThread';
import { parseBlockID } from '../../utils/parse';
import styles from './table-of-contents.module.scss';
import useSidebar from '../../hooks/useSidebar';
import useSession from '@/hooks/useSession';
const TableOfContents = () => {
  const { blocks } = useThread();
  const { pushUserEvent } = useSession();
  const { toggleShowOnMobile } = useSidebar();

  return (
    <ul className={styles['table-of-contents']}>
      {blocks.map(({ id, prompt, queryDate, disliked }) => (
        <li key={id} className={classnames({ [styles['disliked']]: disliked })}>
          <a
            href={`#${parseBlockID(id)}`}
            onClick={() => {
              toggleShowOnMobile();
              pushUserEvent('click_toc_item');
            }}
            title={prompt}
          >
            <strong data-testid="toc-prompt">{prompt}</strong>
            <i>Date: {queryDate.toLocaleString()}</i>
          </a>
        </li>
      ))}
    </ul>
  );
};

export default TableOfContents;
