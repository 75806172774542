import useResponsive from '@/hooks/useResponsive';
import { FC, ReactNode, createContext, useState } from 'react';

interface SidebarContextProps {
  showOnMobile: boolean;
  toggleShowOnMobile: () => void;
}

export const SidebarContext = createContext<SidebarContextProps>({
  showOnMobile: false,
  toggleShowOnMobile: () => {},
});

export const SidebarProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [showOnMobile, setShowOnMobile] = useState(false);
  const toggleShowOnMobile = () => {
    setShowOnMobile((current) => !current);
  };
  const resetShowOnMobile = () => {
    setShowOnMobile(false);
  };
  useResponsive(undefined, resetShowOnMobile);

  return (
    <SidebarContext.Provider value={{ showOnMobile, toggleShowOnMobile }}>
      {children}
    </SidebarContext.Provider>
  );
};
