import HomeLayout from '@/layouts/HomeLayout';
import styles from './status.module.scss';
import SEOHelmet from '@/components/SEOHelmet';
import { useEffect, useState } from 'react';
import { classnames } from '@/utils/functions';
import EmailLink from '@/components/EmailLink';

const Status = () => {
  const isOnMaintenance = !!import.meta.env.VITE_MAINTENANCE_END;
  const [maintenanceTime, setMaintenanceTime] = useState<number | null>(null);

  useEffect(() => {
    if (!isOnMaintenance) return;
    try {
      setMaintenanceTime(
        new Date(import.meta.env.VITE_MAINTENANCE_END).getTime() -
          new Date().getTime()
      );
    } catch (e) {
      setMaintenanceTime(new Date().getTime());
    }
  }, [isOnMaintenance]);

  return (
    <HomeLayout>
      <SEOHelmet
        title="Status"
        description="GANNET Status page"
        index={false}
      />
      <div
        className={classnames(styles['status'], {
          [styles['available']]: !isOnMaintenance,
        })}
      >
        <h1>GANNET</h1>
        <h2>
          Status:{' '}
          <strong>{isOnMaintenance ? 'UNDER MAINTENANCE' : 'ONLINE'}</strong>
        </h2>

        <div className={styles['status-message']}>
          {isOnMaintenance ? (
            <p>
              Our site is currently undergoing scheduled maintenance. We
              apologize for the inconvenience and expect to be back online{' '}
              {maintenanceTime && maintenanceTime > 0 ? (
                <>
                  in{' '}
                  <strong>
                    {Math.ceil(maintenanceTime / 1000 / 60)} minutes
                  </strong>
                </>
              ) : (
                'soon'
              )}
              .
            </p>
          ) : (
            <>
              <p>
                GANNET is currently operational and ready to serve you. Feel
                free to explore and use our services.
              </p>
            </>
          )}
        </div>

        <p className={styles['status-contact']}>
          If you have questions don't hesitate to contact us at <EmailLink />.
        </p>
      </div>
    </HomeLayout>
  );
};

export default Status;
