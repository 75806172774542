import { FC } from 'react';
import styles from './form-message.module.scss';
import { classnames } from '@/utils/functions';

interface FormMessageProps {
  type: 'error' | 'success';
  message: string;
}

const FormMessage: FC<FormMessageProps> = ({ type = 'success', message }) => {
  if (!message) return null;
  return (
    <p className={classnames(styles['form-message'], styles[type])}>
      {message}
    </p>
  );
};

export default FormMessage;
