import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

interface SEOHelmetProps {
  title: string;
  description?: string;
  keywords?: string;
  image?: string;
  index?: boolean;
}

const SEOHelmet: FC<SEOHelmetProps> = ({
  title,
  description,
  keywords,
  image,
  index = true,
}) => {
  const location = useLocation();

  const shouldIndex = index && window.location.origin === 'https://gannet.ai';
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="robots" content={shouldIndex ? 'index, follow' : 'noindex'} />
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta
        property="og:url"
        content={`${window.location.origin}${location.pathname}`}
      />
    </Helmet>
  );
};

export default SEOHelmet;
