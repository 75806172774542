import {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  createContext,
  useState,
} from 'react';
import { Session, DEFAULT_SESSION } from '@/models/session';
import fetchThreads from '@/api/threads/fetchThreads';
import fetchPromptUsage from '@/api/users/fetchPromptUsage';
import { hasThreadsAccess } from '@/utils/session';
import { GTagEventData, gaPushEvent } from '@/utils/analytics';

interface SessionContextProps {
  session: Session;
  setSession: Dispatch<SetStateAction<Session>>;
  refreshThreads: () => void;
  pushUserEvent: (action: string, data?: GTagEventData) => void;
}

interface SessionProviderProps {
  children: ReactNode;
}

export const SessionContext = createContext<SessionContextProps>({
  session: DEFAULT_SESSION,
  setSession: () => ({}),
  refreshThreads: () => {},
  pushUserEvent: (action: string, data: GTagEventData = {}): void => {
    gaPushEvent(action, '', data);
  },
});

export const SessionProvider: FC<SessionProviderProps> = ({ children }) => {
  const [session, setSession] = useState<Session>(DEFAULT_SESSION);

  const pushUserEvent = (action: string, data: GTagEventData = {}): void => {
    gaPushEvent(action, session.email, data);
  };

  const refreshThreads = () => {
    if (!hasThreadsAccess(session.roles)) return;

    setTimeout(() => {
      fetchPromptUsage(session.sub).then((promptUsage) => {
        if (promptUsage.remainingToday === 0) {
          pushUserEvent('out_of_daily_prompts');
        }

        if (promptUsage.remainingThisMonth === 0) {
          pushUserEvent('out_of_monthly_prompts');
        }

        fetchThreads(session.sub).then((threads) => {
          setSession((prev) => ({ ...prev, threads, promptUsage }));
        });
      });
    }, 2000);
  };

  return (
    <SessionContext.Provider
      value={{ session, setSession, refreshThreads, pushUserEvent }}
    >
      {children}
    </SessionContext.Provider>
  );
};
