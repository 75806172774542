import { convertDateToString, getTodayStr } from '@/utils/functions';
import useThread from '../../hooks/useThread';
import styles from './thread-filters.module.scss';
const ThreadFilters = () => {
  const { filters } = useThread();

  const countries =
    filters?.countries?.length > 0 ? filters?.countries.join(', ') : 'All';

  const sources =
    filters?.sources?.length > 0 ? filters?.sources.join(', ') : 'All';

  const dates = `${
    filters?.earliestDate ||
    convertDateToString(new Date('1970-01-01T00:00:00.001Z'))
  } to ${filters?.latestDate || getTodayStr()}`;

  return (
    <div className={styles['thread-filters']}>
      <span>
        <strong>Dates:</strong> {dates}
      </span>
      <span>
        <strong>Countries:</strong> {countries}
      </span>
      <span>
        <strong>Sources:</strong> {sources}
      </span>
    </div>
  );
};

export default ThreadFilters;
