import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import HomeLayout from '@/layouts/HomeLayout';
import SEOHelmet from '@/components/SEOHelmet';
import LoadingSpinner from '@/components/LoadingSpinner';
import { gaPushEvent } from '@/utils/analytics';
import { confirmSignUp } from 'aws-amplify/auth';
import styles from './confirm-user.module.scss';
import Button from '@/components/Button';
import EmailLink from '@/components/EmailLink';

const ConfirmUser = () => {
  const [loading, setLoading] = useState(true);
  const [confirmed, setConfirmed] = useState(false);
  const navigate = useNavigate();
  const { search } = useLocation();

  useEffect(() => {
    if (loading === false) return;
    const queryParams = new URLSearchParams(location.search);

    setTimeout(() => {
      confirmSignUp({
        username: decodeURIComponent(queryParams.get('user_name') as string),
        confirmationCode: queryParams.get('confirmation_code') as string,
      })
        .then((res) => {
          setConfirmed(res.isSignUpComplete);
          gaPushEvent('account_verified');
        })
        .catch((err) => {
          console.log(err.message);

          if (
            err.message ===
            'User cannot be confirmed. Current status is CONFIRMED'
          ) {
            setConfirmed(true);
            gaPushEvent('account_already_verified');
          } else {
            gaPushEvent('account_verification_error');
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }, 10);
  }, [loading, search]);

  return (
    <HomeLayout showNav={false}>
      <SEOHelmet
        title="Verify Account"
        description="Verify your account to join GANNET"
        index={false}
      />

      <div className={styles['confirm-user-wrapper']}>
        {loading === true ? (
          <>
            <p>Please wait until we verify your account.</p>
            <LoadingSpinner />
          </>
        ) : confirmed ? (
          <>
            <h1>Account Verified</h1>
            <p>
              Your account has been verified successfully. You can now access
              all features of <strong>GANNET</strong>, please sign in to
              continue:
            </p>

            <Button onClick={() => navigate('/sign-in')}>SIGN IN</Button>
          </>
        ) : (
          <>
            <p>
              An error happened while verifying your account. Please try again
              later.
            </p>
            <p>
              If the issue persists please contact us at <EmailLink />
            </p>
          </>
        )}
      </div>
    </HomeLayout>
  );
};

export default ConfirmUser;
