import { FC, FormEvent, ReactNode } from 'react';
import styles from './form.module.scss';

interface FormProps {
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  children: ReactNode;
}

const Form: FC<FormProps> = ({ onSubmit, children }) => {
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    await onSubmit(e);
  };

  return (
    <form className={styles['form']} onSubmit={handleSubmit}>
      {children}
    </form>
  );
};

export default Form;
