import {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import styles from './password-input.module.scss';
import InputWrapper from '../Form/InputWrapper';
interface PasswordInputProps {
  id?: string;
  label?: string;
  hideRequiredStyle?: boolean;
  autoFocus?: boolean;
  password: string;
  setPassword: Dispatch<SetStateAction<string>>;
  customPasswordValidations?: () => string;
}

const PasswordInput: FC<PasswordInputProps> = ({
  id = 'password',
  label = 'Password',
  hideRequiredStyle = false,
  password,
  setPassword,
  customPasswordValidations = null,
  autoFocus = false,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current && customPasswordValidations !== null) {
      inputRef.current.setCustomValidity(customPasswordValidations());
    }
  }, [customPasswordValidations, password]);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  return (
    <InputWrapper
      className={styles['password-input-wrapper']}
      hideRequiredStyle={hideRequiredStyle}
    >
      <input
        ref={inputRef}
        type={showPassword ? 'text' : 'password'}
        id={id}
        name={id}
        autoFocus={autoFocus}
        autoComplete="off"
        onChange={onPasswordChange}
        value={password}
        required
      />
      <label htmlFor={id}>{label}:</label>
      <button type="button" tabIndex={-1} onClick={toggleShowPassword}>
        {showPassword ? '🕶' : '👓'}
      </button>
    </InputWrapper>
  );
};

export default PasswordInput;
