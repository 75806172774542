import { ThreadStatus } from '@/models/thread';
import { getRandomNumber } from '@/utils/functions';
import { useEffect, useState } from 'react';

const STEPS = [
  'Analyzing the query',
  'Identifying key themes',
  'Searching for relevant humanitarian datasets',
  'Retrieving relevant sources and data',
  'Analyzing data for insights',
  'Cross-referencing with latest reports',
  'Compiling findings',
  'Generating a response',
];

const useLoadingText = (status: ThreadStatus) => {
  const [ellipsis, setEllipsis] = useState<string>('');
  const [currentStep, setCurrentStep] = useState<number>(0);

  useEffect(() => {
    let ellipsisInterval: NodeJS.Timeout;
    if (status === 'FETCHING') {
      ellipsisInterval = setInterval(() => {
        if (status !== 'FETCHING') clearInterval(ellipsisInterval);
        setEllipsis((curr) => {
          if (curr === '...') {
            return '';
          } else {
            return curr + '.';
          }
        });
      }, 250);

      const getRandomTimeoutDelay = (): number => {
        return getRandomNumber(3, 12) * 1000;
      };

      const updateTimeout = () => {
        if (status === 'FETCHING') {
          setCurrentStep((curr) => {
            if (curr <= STEPS.length - 2) {
              const delay = getRandomTimeoutDelay();
              setTimeout(updateTimeout, delay);
              return curr + 1;
            } else {
              return curr;
            }
          });
        }
      };

      setTimeout(updateTimeout, getRandomTimeoutDelay());
    } else {
      setEllipsis('');
      setCurrentStep(0);
    }

    return () => {
      clearInterval(ellipsisInterval);
    };
  }, [status]);

  return `${STEPS[currentStep]}${ellipsis}`;
};

export default useLoadingText;
