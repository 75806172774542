import styles from './input-filters.module.scss';
import countries from '@/data/countries.json';
import sources from '@/data/sources.json';
import { FilterModalOptions } from './utils';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import FilterModal from './FilterModal';
import FilterSummary from './FilterSummary';
import { getTodayStr } from '@/utils/functions';

interface InputFiltersProps {
  selectedCountries: string[];
  selectedSources: string[];
  earliestDate: string;
  latestDate: string;
  setSelectedCountries: Dispatch<SetStateAction<string[]>>;
  setSelectedSources: Dispatch<SetStateAction<string[]>>;
  setEarliestDate: Dispatch<SetStateAction<string>>;
  setLatestDate: Dispatch<SetStateAction<string>>;
}
const InputFilters: FC<InputFiltersProps> = ({
  selectedCountries,
  selectedSources,
  earliestDate,
  latestDate,
  setSelectedCountries,
  setSelectedSources,
  setEarliestDate,
  setLatestDate,
}) => {
  const today = getTodayStr();
  const [isSingleDate, setIsSingleDate] = useState<boolean>(true);
  const [showOptions, setShowOptions] = useState<FilterModalOptions>(null);

  return (
    <>
      <div className={styles['input-filters']}>
        <FilterSummary
          label="Countries"
          value={selectedCountries.join(', ')}
          handleClick={() => setShowOptions('countries')}
        />
        <FilterSummary
          label="Sources"
          value={selectedSources.join(', ')}
          handleClick={() => setShowOptions('sources')}
        />
        <FilterSummary
          label="Date"
          value={`${
            !isSingleDate && earliestDate ? `${earliestDate} → ` : ''
          }${latestDate}`}
          fallbackValue={today}
          handleClick={() => setShowOptions('date')}
        />
      </div>

      <FilterModal
        countries={countries.map((country) => country.title)}
        sources={sources.map((sources) => sources.title)}
        showOptions={showOptions}
        setShowOptions={setShowOptions}
        selectedCountries={selectedCountries}
        selectedSources={selectedSources}
        earliestDate={earliestDate}
        latestDate={latestDate}
        isSingleDate={isSingleDate}
        setIsSingleDate={setIsSingleDate}
        setSelectedCountries={setSelectedCountries}
        setSelectedSources={setSelectedSources}
        setEarliestDate={setEarliestDate}
        setLatestDate={setLatestDate}
      />
    </>
  );
};

export default InputFilters;
