import { ResponseSource } from '@/models/thread';
import useThread from '../../hooks/useThread';
import styles from './bibliography.module.scss';
import { useEffect, useState } from 'react';
import useSession from '@/hooks/useSession';

interface SourceListItem extends ResponseSource {
  count: number;
}
const Bibliography = () => {
  const { pushUserEvent } = useSession();
  const { blocks, status } = useThread();
  const [sources, setSources] = useState<SourceListItem[]>([]);
  // Reduce the sources on the blocks to a unique list of sources and their counts

  useEffect(() => {
    if (status !== 'READY') return;

    const sources: SourceListItem[] = blocks.reduce((acc, block) => {
      if (!block.sources) return acc;
      block.sources.forEach((source) => {
        const existingSource = acc.find((s) => s.url === source.url);
        if (existingSource) {
          existingSource.count++;
        } else {
          acc.push({ ...source, count: 1 });
        }
      });
      return acc;
    }, [] as SourceListItem[]);

    setSources(sources);
  }, [blocks, status]);

  return (
    <ul className={styles['bibliography']}>
      {sources.map(({ title, url, count }) => (
        <li key={url}>
          <a
            href={url}
            title={`${title} | ${url}`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              pushUserEvent('click_bibliography_item');
            }}
          >
            <span>{title}</span>
            <i>
              Linked {count} time{count !== 1 && 's'}
            </i>
          </a>
        </li>
      ))}
    </ul>
  );
};

export default Bibliography;
