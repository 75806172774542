import { FC, useState } from 'react';
import styles from './multi-option-input.module.scss';
import { classnames } from '@/utils/functions';
interface MultiOptionInputProps {
  options: string[];
  selectedOptions: string[];
  name: string;
  variation?: 'filters' | 'options';
  handleChange: (name: string, value: string, selected: boolean) => void;
}

const MultiOptionInput: FC<MultiOptionInputProps> = ({
  options,
  selectedOptions,
  name,
  variation = 'filters',
  handleChange,
}) => {
  const [query, setQuery] = useState('');

  const filteredOptions = options.filter((item) =>
    item.toLowerCase().includes(query.toLowerCase())
  );
  const selectedOptionsStr = selectedOptions.join(', ');
  selectedOptions;
  const checkIfSelected = (id: string) => {
    return selectedOptions.some((option) => option === id);
  };

  return (
    <div
      className={classnames(styles['multi-option-input'], {
        [styles[variation]]: true,
      })}
    >
      {variation === 'filters' && <strong>{name}:</strong>}

      <div>
        {variation === 'filters' && (
          <input
            type="search"
            placeholder={`Type to search ${name}`}
            onChange={(e) => setQuery(e.target.value)}
          />
        )}
        <ul>
          {filteredOptions.map((item) => {
            const isSelected = checkIfSelected(item);
            return (
              <li key={item}>
                <button
                  type="button"
                  className={isSelected ? styles['selected'] : ''}
                  title={item}
                  onClick={() => handleChange(name, item, !isSelected)}
                >
                  {item}
                </button>
              </li>
            );
          })}
        </ul>
      </div>

      {variation === 'filters' && (
        <span title={selectedOptionsStr}>
          {selectedOptions.length === 0 ? (
            `Select ${name} to filter.`
          ) : (
            <>
              <strong>Selected ({selectedOptions.length}): </strong>
              {selectedOptionsStr}
            </>
          )}
        </span>
      )}
    </div>
  );
};

export default MultiOptionInput;
