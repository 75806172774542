import { AWS_LLM_API } from '@/config/aws';
import { extractPayload, getSubHeader } from '@/utils/api';
import { patch } from 'aws-amplify/api';

interface UpdateTitlePayload {
  success: boolean;
  errorInfo: {
    stack: string;
    message: string;
  };
}

const updateTitle = async (
  sub: string,
  threadID: string,
  title: string
): Promise<boolean> => {
  try {
    const { response } = patch({
      apiName: AWS_LLM_API,
      path: `/v1/base/conversation/threads/${threadID}`,
      options: {
        headers: getSubHeader(sub),
        body: { title },
      },
    });

    const payload = await extractPayload<UpdateTitlePayload>(response);
    return payload.success;
  } catch (error) {
    console.error(error);

    return false;
  }
};

export default updateTitle;
