import styles from './disclaimer.module.scss';

const Disclaimer = () => {
  return (
    <p className={styles.disclaimer}>
      Errors are possible with GANNET. It is advisable to verify crucial
      information.
    </p>
  );
};

export default Disclaimer;
