import { classnames } from '@/utils/functions';
import styles from './msg-paragraph.module.scss';
import { FC, ReactNode } from 'react';

interface MsgParagraphProps {
  children: ReactNode;
}

const MsgParagraph: FC<MsgParagraphProps> = ({ children }) => {
  return <p className={classnames(styles['result-paragraph'])}>{children}</p>;
};

export default MsgParagraph;
