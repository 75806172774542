import { Thread } from './thread';
import { Role } from './user';

export interface Session {
  sub: string;
  email: string;
  roles: Role[];
  threads: Thread[];
  promptUsage: PromptUsage;
}

export interface PromptUsage {
  perDay: number;
  perMonth: number;
  usedToday: number;
  usedThisMonth: number;
  remainingToday: number;
  remainingThisMonth: number;
}

export const DEFAULT_SESSION: Session = {
  sub: '',
  email: '',
  roles: [],
  threads: [],
  promptUsage: {
    perDay: 0,
    perMonth: 0,
    usedToday: 0,
    usedThisMonth: 0,
    remainingToday: 0,
    remainingThisMonth: 0,
  },
};
